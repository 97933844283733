 /deep/ .el-cascader {
  margin-top: 3px;
}
 /deep/ .el-input-number--small {
  width: 100%;
}
.labelRowTwo /deep/ .el-form-item__label {
  line-height: 20px;
}
 /deep/ .el-dialog .el-input__icon {
  position: relative;
  top: -3px !important;
}
 /deep/ .el-textarea .el-input__count {
  line-height: 20px;
}
.centerLabelCenterBox /deep/ .el-form-item__label {
  text-align: center !important;
  padding: 0 !important;
}
.addlist h3 {
  padding: 10px 0;
}
.zsBtn {
  margin-left: 20px;
}
.zsBtn button {
  height: 40px;
}
.certificate-box > div:last-child {
  border: 0;
}
.card-border {
  padding: 10px;
  border-bottom: 1px dashed #eee;
}
